// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3f2fd;
$primaryMain : #2196f3;
$primaryDark : #1e88e5;
$primary200  : #90caf9;
$primary800  : #1565c0;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain : #305a8a;
$secondaryDark : #376895;
$secondary200  : #b39ddb;
$secondary800  : #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200  : #69f0ae;
$successMain : #00e676;
$successDark : #00c853;

// error
$errorLight: #ef9a9a;
$errorMain : #f44336;
$errorDark : #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain : #ffab91;
$orangeDark : #d84315;

// warning
$warningLight: #fff8e1;
$warningMain : #ffe57f;
$warningDark : #ffc107;

// grey
$grey50 : #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #217eab;

//green 
$green250 : #007F6E;
// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper     : #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain : #2196f3;
$darkPrimaryDark : #1e88e5;
$darkPrimary200  : #90caf9;
$darkPrimary800  : #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain : #7c4dff;
$darkSecondaryDark : #651fff;
$darkSecondary200  : #b39ddb;
$darkSecondary800  : #6200ea;

// text variants
$darkTextTitle    : #d7dcec;
$darkTextPrimary  : #bdc8f0;
$darkTextSecondary: #8492c4;

// blue variants
$blue900    : #152e51;
$blueGrey100: #cfd8dc;
$blueGrey700: #455a64;
$blueGrey050: #eceff1;
// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200  : $primary200;
    primaryMain : $primaryMain;
    primaryDark : $primaryDark;
    primary800  : $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200  : $secondary200;
    secondaryMain : $secondaryMain;
    secondaryDark : $secondaryDark;
    secondary800  : $secondary800;

    // success
    successLight: $successLight;
    success200  : $success200;
    successMain : $successMain;
    successDark : $successDark;

    // error
    errorLight: $errorLight;
    errorMain : $errorMain;
    errorDark : $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain : $orangeMain;
    orangeDark : $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain : $warningMain;
    warningDark : $warningDark;

    // grey
    grey50 : $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // Green 
    green250: $green250;
    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper     : $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle    : $darkTextTitle;
    darkTextPrimary  : $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain : $darkPrimaryMain;
    darkPrimaryDark : $darkPrimaryDark;
    darkPrimary200  : $darkPrimary200;
    darkPrimary800  : $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain : $darkSecondaryMain;
    darkSecondaryDark : $darkSecondaryDark;
    darkSecondary200  : $darkSecondary200;
    darkSecondary800  : $darkSecondary800;

    blue900    : $blue900;
    blueGrey050: $blueGrey050;
    blueGrey100: $blueGrey100;
    blueGrey700: $blueGrey700;
}