// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
.d-flex {
  display: flex;
}
.text-center {
  text-align: center;
}
.m-r-15px {
  margin-right: 15px;
}
.w-space-nowrap {
  white-space: nowrap;
}
.tracking_details {
  margin: auto;
  max-width: 700px;
  width: 100%;
}
.details_info {
  margin: 20px 0 30px;
}
#tracking_no {
  width: 500px;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  #tracking_no {
    width: 100%;
  }
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
// .MuiDataGrid-columnSeparator{
//     display: none !important;
// }
.MuiTableCell-head {
  font-weight: bold !important;
}
.previewImage {
  vertical-align: top;
  border-radius: 10%;
  margin: 0;
}
.selected-comp-logo {
  vertical-align: top;
  border-radius: 10%;
  margin-right: 5px;
  width: 100%;
  //height: 100%;
}
.warning-datagrid {
  background-color: #ffdddd !important;
}

.isnotsave-datagrid {
  background-color: #ffffcc !important;
}
.mapboxgl-popup-content h3 {
  margin: 0;
}
.mapboxgl-popup-content p {
  margin: 0;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
.marker {
    background-image: url('../images/map/wareHouse.svg');
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
.marker-company {
    background-image: url('../images/map/moving-company.svg');
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }

.main-items {
  align-items: center;
  display: flex;
}

.main-items-sub {
   display: flex;
  align-items: center;
  margin-left: 20px;
}

.rotateimg180 {
  transform: rotate(180deg);
}
.download-csv {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #B0D900;
  margin-right: 5px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.shipment-status {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  margin-left: 10px;
}

.chipsActive {
  border: 3px solid #b0d907;
  cursor: pointer;
}
.list-container {
  display: flex;
  flex-direction: column;
}

// ReactTable
.ReactTable {
    td, th {
      text-align: left;
  }
}

.table {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}